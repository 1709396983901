import React, { createContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ProjectInfo from '../../components/tools/staking/ProjectInfo'
import SolanaWalletProvider from '../../components/utility/WalletConnect/SolanaWalletProvider'
import { setNftDataInfo, setProjectData } from '../../javascript/tools/staking'
import PageNotFound from '../utility/PageNotFound'
import Alert from './../../components/utility/Alert.js'
import './../../css/tools/royalty.css'
import preloader from './../../assets/img/utility/preloader.gif'
import LockedNFTs from '../../components/tools/staking/LockedNFTs'
import { Helmet } from 'react-helmet'
import { useWallet } from '@solana/wallet-adapter-react'
export const RoyaltyContext = createContext({
  project: undefined,
  walletAddress: undefined,
  nftData: undefined
})
export default function Royalty() {
  const [alert, setAlert] = useState([])
  const [pageError, setPageError] = useState('Not Loaded Yet')
  const [project, setProject] = useState()
  const [projectCss, setProjectCss] = useState()
  useEffect(() => {
    if (project && project.cssFile) {
      const setCssFile = async () => {
        if (project.cssFile) {
          const cssFile = await fetch(project.cssFile)
          if (cssFile) {
            const css = await cssFile.text()
            if (css) {
              setProjectCss(css)
            }
          }
        } else {
          setProjectCss()
        }
      }
      setCssFile(project.cssFile)
    }
  }, [project])
  const [nftData, setNftData] = useState([])
  const [walletAddress, setWalletAddress] = useState()
  const {publicKey} = useWallet()
  const params = useParams()
  useEffect(() => {
    setProjectData(params, setPageError, setProject)
  }, [params, walletAddress])

  useEffect(() => {
    if (publicKey) {
      setNftDataInfo(params.id, setPageError, publicKey, setNftData)
    } else {
      setNftData([])
    }
  }, [publicKey, params.id])

  if (pageError === true) {
    return (
      <PageNotFound />
    )
  }
  if (project && nftData) {
    return (
      <>
        {projectCss && (
          <Helmet>
            <style>
              {projectCss}
            </style>
          </Helmet>
        )}
        <RoyaltyContext.Provider value={{ project, walletAddress, nftData }}>
            <div className='royalty-tool__bg'>
              <div className='royalty-tool__bg-inner'>
                <ProjectInfo project={project} walletAddress={[walletAddress, setWalletAddress]} />
                <Alert alertState={[alert, setAlert]} />
                <LockedNFTs setAlert={setAlert} setNftData={setNftData}/>
              </div>
            </div>
        </RoyaltyContext.Provider>
      </>
    )
  } else {
    return (
      <div style={{ marginTop: '20px', backgroundColor: '#221450', minWidth: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div className='font-signika' style={{ borderRadius: '15px', color: 'white', display: 'flex', alignItems: 'center', textAlign: 'center', maxWidth: '80vw', fontSize: '2.5rem', fontWeight: '700', paddingLeft: '25px', paddingRight: '25px', paddingTop: '15px', paddingBottom: '15px', height: 'fit-content' }}>
          <img src={preloader} style={{ width: '250px', maxWidth: '60vw' }} alt='preloader' />
        </div>
      </div>
    )
  }

}