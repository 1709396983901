import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './css/utility.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import 'boxicons'
import PageNotFound from './pages/utility/PageNotFound';
import Royalty from './pages/tools/Royalty';
import SolanaWalletProvider from './components/utility/WalletConnect/SolanaWalletProvider';
// import PageNotFound from './pages/utility/PageNotFound';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <SolanaWalletProvider>
    <BrowserRouter>
      <Routes>
        <Route path='/*' element={<PageNotFound />} />
        <Route path='/:id' element={<Royalty />} />
      </Routes>
    </BrowserRouter>
  </SolanaWalletProvider>
);
