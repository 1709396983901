import React, { useContext, useState } from 'react'
import { RoyaltyContext } from '../../../pages/tools/Royalty';
import { LAMPORTS_PER_SOL } from '@solana/web3.js';
import preloader from './../../../assets/img/utility/preloader.gif'
import { useWallet } from '@solana/wallet-adapter-react';
import { payRoyalty } from '../../../javascript/tools/staking';
import { useConnection } from '@solana/wallet-adapter-react';
export default function LockedNFTs({ setAlert, setNftData}) {
  const { nftData, project, walletAddress } = useContext(RoyaltyContext)
  const nftsOfCollection = nftData.nftsOfCollection
  const [royalty, setRoyalty] = useState(0)
  const { publicKey, sendTransaction } = useWallet()
  const [disablePayRoyaltyBtn, setDisablePayRoyaltyBtn] = useState(false)
  const [nftsChosen, setNftsChosen] = useState([])
  const { connection } = useConnection()

  let currency = 'SOL'

  const handleChange = () => {
    try {
      const answers = document.getElementById('royalty-tool__stake-form')
      let nftsChosenArray = []
      for (var i = 0; i < answers.length; i++) {
        if (answers.elements[i].type !== 'submit') {
          if (answers.elements[i].checked) {
            nftsChosenArray.push(answers.elements[i].id);
          }
        }
      }
      const nftsChosenData = nftData.nftsOfCollection.filter(x => nftsChosenArray.includes(x.mintAddress))
      setNftsChosen(nftsChosenData)
      let value = 0
      nftsChosenData.forEach(x => {
        value += x.royalty.price
      })
      const percent = project.royalty.percent
      let royalty = percent / 100 * value
      royalty = (royalty / LAMPORTS_PER_SOL)
      setRoyalty(royalty)
    } catch {
      setRoyalty(0)
    }
  }
  if (!walletAddress) {
    return (
      <div style={{ marginTop: '20px', minWidth: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div className='font-signika' style={{ borderRadius: '15px', color: 'white', display: 'flex', alignItems: 'center', textAlign: 'center', maxWidth: '80vw', fontSize: '2.5rem', fontWeight: '700', paddingLeft: '25px', paddingRight: '25px', paddingTop: '15px', paddingBottom: '15px', height: 'fit-content' }}>
          You have not connected your wallet.
        </div>
      </div>
    )
  }
  if (!nftsOfCollection) return (
    <div style={{ marginTop: '20px', minWidth: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div className='font-signika' style={{ borderRadius: '15px', color: 'white', display: 'flex', alignItems: 'center', textAlign: 'center', maxWidth: '80vw', fontSize: '2.5rem', fontWeight: '700', paddingLeft: '25px', paddingRight: '25px', paddingTop: '15px', paddingBottom: '15px', height: 'fit-content' }}>
        <img src={preloader} style={{ width: '200px', maxWidth: '60vw' }} alt='preloader' />
      </div>
    </div>
  )
  if (!nftsOfCollection.length) {
    return (
      <div style={{ marginTop: '20px', minWidth: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div className='font-signika' style={{ borderRadius: '15px', color: 'white', display: 'flex', alignItems: 'center', textAlign: 'center', maxWidth: '80vw', fontSize: '2.5rem', fontWeight: '700', paddingLeft: '25px', paddingRight: '25px', paddingTop: '15px', paddingBottom: '15px', height: 'fit-content' }}>
          You do not have any {project.name} NFT.
        </div>
      </div>
    )
  }
  if (!nftsOfCollection.filter(y => y.locked && y.royalty && y.royalty.price).length) {
    return (
      <div style={{ marginTop: '20px', minWidth: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div className='font-signika' style={{ borderRadius: '15px', color: 'white', display: 'flex', alignItems: 'center', textAlign: 'center', maxWidth: '80vw', fontSize: '2.5rem', fontWeight: '700', paddingLeft: '25px', paddingRight: '25px', paddingTop: '15px', paddingBottom: '15px', height: 'fit-content' }}>
          You have paid royalty for all your {project.name} NFT.
        </div>
      </div>
    )
  }
  return (
    <div style={{ paddingTop: '2rem', paddingBottom: '20px' }}>
      {
        <form id='royalty-tool__stake-form' onSubmit={(event) => payRoyalty(nftsChosen, royalty, sendTransaction, publicKey, project, setDisablePayRoyaltyBtn, event, connection, setAlert, setNftData)} onChange={handleChange}>
          <div className='royalty-tool__pay-royalty-flex-container'>
            {nftsOfCollection.filter(y => y.locked && y.royalty && y.royalty.price).map(x => {
              return (
                <div key={x.mintAddress}>
                  <div className='royalty-tool__pay-royalty-locked-nft'>
                    <input type={'checkbox'} id={x.mintAddress} className='royalty-tool__pay-royalty-nft-input-checkbox' />
                    <label htmlFor={x.mintAddress} className='royalty-tool__pay-royalty-nft-label'>
                      <div className='royalty-tool__pay-royalty-nft' style={{ background: `url(${x.image})`, backgroundSize: 'cover' }}>
                        <div className='royalty-tool__lock-nft'>
                          <img src='https://cdn.discordapp.com/emojis/808369470866849852.webp?size=128&quality=lossless' style={{ height: '50%', borderRadius: '10px', display: 'block', margin: 'auto' }} alt={`${x.mintAddress} locked`} />
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              )
            })}
          </div>

          {royalty && (<input type={'submit'} disabled={disablePayRoyaltyBtn} value={`Pay ${royalty} ${currency}`} className='royalty-tool_pay-royalty-form-submit-btn' />)}
        </form>
      }
    </div>

  )
}
