import { useWallet } from '@solana/wallet-adapter-react'
import { WalletDisconnectButton, WalletMultiButton } from '@solana/wallet-adapter-react-ui'
import React, { useEffect } from 'react'

export default function ConnectButton(props) {
    const { publicKey } = useWallet()
    // const publicKey = 'BPJVn3uDifgDvmxv2iy4cPFaR663S44GAc5ZvnidrJTr'
    const [, setWalletAddress] = props.walletAddress
    const project = props.project
    useEffect(() => {
        if (project.blockchain === 'Solana') {
            if (publicKey) {
                setWalletAddress(publicKey.toString())
            } else {
                setWalletAddress()
            }
        } else {
            setWalletAddress()
        }
    }, [publicKey, project.blockchain, setWalletAddress])

    if (project.blockchain === 'Solana') {
        if (!publicKey) {
            return (
                <WalletMultiButton className='button-orange-border button-cyan-border' style={{ fontSize: "1.3rem" }}></WalletMultiButton>
            )
        } else {
            return (
                <WalletDisconnectButton className='button-orange-border button-cyan-border' style={{ fontSize: "1.3rem" }} />
            )
        }
    }
}
