import React from 'react'
import ConnectButton from '../../utility/Tools/ConnectButton'

export default function StakingProjectInfo({ project, walletAddress }) {
    return (
        <>
            <div className='royalty-tool__project-info-tab'>
                <div className='royalty-tool__project-info'>
                    <img src={project.logo} className='royalty-tool__project-img' alt="Project" />
                    <div className='royalty-tool__project-text font-robot'>
                        {project.name}
                    </div>
                    <div className='royalty-tool__connect-btn'>
                        <div style={{ right: "20px" }}>
                            <ConnectButton project={project} walletAddress={walletAddress} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}