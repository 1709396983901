import { Config } from "../../config"
import { showAlert } from './../utility/utility.js'
import { LAMPORTS_PER_SOL, PublicKey, SystemProgram, Transaction } from "@solana/web3.js"


export const payRoyalty = async (nfts, royaltyAmount, sendTransaction, publicKey, project, setDisablePayRoyaltyBtn, event, connection, setAlert, setNftData) => {
    event.preventDefault()
    try {
        setDisablePayRoyaltyBtn(true)
        const transaction = new Transaction()
        transaction.add(SystemProgram.transfer({ fromPubkey: publicKey, toPubkey: new PublicKey(project.royalty.wallet), lamports: Math.round(royaltyAmount * LAMPORTS_PER_SOL) }))
        if(project.royalty.fees) {
            transaction.add(SystemProgram.transfer({
                fromPubkey: publicKey,
                toPubkey: new PublicKey(project.royalty.fees.wallet),
                lamports: Math.round(project.royalty.fees.amount * LAMPORTS_PER_SOL)
            }))
        }
        const signature = await sendTransaction(transaction, connection)
        await connection.confirmTransaction(signature)
        const req = await fetch(`${Config.apiURL}/pay-royalty`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                walletAddress: publicKey.toString(),
                nfts: nfts.map(x => x.mintAddress),
                signature: signature,
                projectId: project.id
            })
        })
        const res = await req.json()
        if (res.error) showAlert(setAlert, res.error)
        if (res.success) {
            showAlert(setAlert, res.success, 'success')
            setNftData((prevState) => {
                const lockedNfts = prevState.nftsOfCollection.filter(x => nfts.find(y => y.mintAddress === x.mintAddress))
                lockedNfts.forEach(x => {
                    x.locked = false
                })
                return prevState
            })
        }
        setDisablePayRoyaltyBtn(false)
    } catch (err) {
        console.log(err)
        showAlert(setAlert, `An unknown error occured`)
        setDisablePayRoyaltyBtn(false)
    }
}
export const setProjectData = async (params, setPageError, setProject) => {
    try {
        const id = params.id
        const projectInfo = await fetch(`${Config.apiURL}/get-project`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                projectId: id
            })
        })
        const projectInfoJson = await projectInfo.json()
        if (projectInfoJson.error) {
            setPageError(true)
        } else {
            setProject(projectInfoJson)
        }
    } catch {
        setPageError(true)
    }
}

export const setNftDataInfo = async (params, setPageError, walletAddress, setNftData) => {
    try {
        const id = params
        const payload = { projectId: id }
        if (walletAddress) payload.walletAddress = walletAddress
        const projectInfo = await fetch(`${Config.apiURL}/get-staking-info`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(payload)
        })
        const projectInfoJson = await projectInfo.json()
        if (projectInfoJson.error) {
            setPageError(true)
        } else {
            setNftData(projectInfoJson)
        }
    } catch {
        setPageError(true)
    }
}